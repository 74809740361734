$(document).ready(() => {
    actualizarHorario();
});

function actualizarHorario() {
    try {
        // Obtener la fecha actual ajustada a la zona horaria de Colombia (UTC-5)
        const fechaActual = new Date();
        const opcionesColombia = { timeZone: "America/Bogota", weekday: "long", hour: "numeric", minute: "numeric", hourCycle: "h23" };
        const partes = new Intl.DateTimeFormat("es-CO", opcionesColombia).formatToParts(fechaActual);

        // Mapear las partes para acceso más rápido
        const datos = partes.reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {});

        const diaSemana = datos.weekday?.toLowerCase();
        const hora = parseInt(datos.hour, 10);
        const minutos = parseInt(datos.minute, 10);

        // Validaciones rápidas
        if (!diaSemana || isNaN(hora) || isNaN(minutos)) {
            throw new Error("Datos de tiempo incompletos o inválidos.");
        }

        // Definir horarios
        const horarios = {
            domingo: { abierto: [], texto: "domingo", horario: "Cerrado" },
            lunes: { abierto: [[8, 12], [14, 17]], texto: "lunes", horario: "08:00AM - 12:00PM </br> 02:00PM - 05:00PM" },
            martes: { abierto: [[8, 12], [14, 17]], texto: "martes", horario: "08:00AM - 12:00PM </br> 02:00PM - 05:00PM" },
            miércoles: { abierto: [[8, 12], [14, 17]], texto: "miércoles", horario: "08:00AM - 12:00PM </br> 02:00PM - 05:00PM" },
            jueves: { abierto: [[8, 12], [14, 17]], texto: "jueves", horario: "08:00AM - 12:00PM </br> 02:00PM - 05:00PM" },
            viernes: { abierto: [[8, 12], [14, 17]], texto: "viernes", horario: "08:00AM - 12:00PM </br> 02:00PM - 05:00PM" },
            sábado: { abierto: [[8, 12]], texto: "sábado", horario: "08:00AM - 12:00PM" },
        };

        const dia = horarios[diaSemana];
        if (!dia) throw new Error(`No se encontraron horarios para el día: ${diaSemana}`);

        // Verificar si está abierto
        const estaAbierto = dia.abierto.some(([inicio, fin]) => hora >= inicio && hora < fin);
        const officestatus = estaAbierto ? "Abierto" : "Cerrado";
        const cssdate = estaAbierto ? "opendate" : "closedate";

        // Generar HTML una vez
        const officehoursHTML = `
            Horario de Atención hoy ${dia.texto}: </br> ${dia.horario} </br>
            Estado : <span class="${cssdate}" itemprop="openingHours"><i class="icon-check icons"></i> ${officestatus}</span>
        `;

        // Actualizar DOM solo si cambia
        const $officeHoursElement = $("#officehours");
        if ($officeHoursElement.html().trim() !== officehoursHTML.trim()) {
            $officeHoursElement.html(officehoursHTML);
        }

        // Sincronizar próxima actualización al inicio del próximo minuto
        const segundosRestantes = 60 - fechaActual.getSeconds();
        setTimeout(actualizarHorario, segundosRestantes * 1000);

    } catch (error) {
        console.error("Error en la función actualizarHorario:", error.message);
    }
}
